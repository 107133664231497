<script lang="ts" setup>
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

/**
 * This is a pure UI component.
 * The component provides no own click-handler or tab-index.
 * If you need it interactive, use it within the aria-list-box component.
 * If you not want the visual hover-effect, set 'disableHover' to true
 */

defineProps<{
  label: string;
  icon?: string;
  isSelected?: boolean;
  isActive?: boolean;
  mood?: 'neutral' | 'superNeutral' | 'positive' | 'negative';
  disableHover?: boolean;
}>();

</script>

<template>
  <div
    v-ripple="!disableHover"
    class="answer-option-chip text-md-bold"
    :class="{
      'answer-option-chip--selected': isSelected,
      'answer-option-chip--active': isActive,
      'answer-option-chip--positive': mood === 'positive',
      'answer-option-chip--negative': mood === 'negative',
      'answer-option-chip--neutral': mood === 'neutral',
      'answer-option-chip--super-neutral': mood === 'superNeutral',
      'answer-option-chip--no-hover': Boolean(disableHover),
    }"
  >
    <svg-illustration v-if="icon" :name="icon"/>
    <span>{{ label }}</span>
  </div>
</template>

<style lang="scss" scoped>

.answer-option-chip {
  display: flex;
  gap: var(--Spacing-spacing-2);
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  padding: var(--Spacing-spacing-1) var(--Spacing-spacing-2);
  color: var(--Neutral-UI-neutral-ui-100);
  background-color: var(--Neutral-UI-neutral-ui-10);
  border-radius: 21px;
  min-width: 75px;
  justify-content: center;
  user-select: none;

  &:not(#{&}--no-hover) {
    cursor: pointer;
  }

  &--super-neutral {
    background-color: var(--Neutral-UI-neutral-ui-30);
  }

  // selected default (neutral moods)
  &--selected {
    background-color: var(--Neutral-UI-neutral-ui-80);
    color: var(--Neutral-UI-neutral-ui-10);
  }

  // selected and positive mood
  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &--positive#{&}--selected {
    background-color: var(--Secondary-secondary-color-2);
    color: var(--Neutral-UI-neutral-ui-100);
  }

  // selected and negative mood
  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &--negative#{&}--selected {
    background-color: var(--Secondary-secondary-color-5);
    color: var(--Neutral-UI-neutral-ui-100);
  }

  // hover and active
  &:not(#{&}--selected):not(#{&}--no-hover):hover {
    background-color: var(--Neutral-UI-neutral-ui-20);
  }

  // hover and active for mood 'super-neutral'
  &--super-neutral:not(#{&}--selected):not(#{&}--no-hover):hover {
    background-color: var(--Neutral-UI-neutral-ui-40);
  }
}

// keyboard control only
:focus-visible .answer-option-chip--active {
  @include focus-visible-default-styling;

  &:not(.answer-option-chip--selected) {
    background-color: var(--Neutral-UI-neutral-ui-20);
  }

  &.answer-option-chip--positive {
    outline-color: var(--Primary-primary-color-2) !important;
  }

  &.answer-option-chip--negative {
    outline-color: var(--Primary-primary-color-3) !important;
  }
}

</style>