<script lang="ts" setup>

import {watch} from 'vue';
import {ColorScheme, useUserPreferencesStore} from '@/ts/store/user-preferences.store';
import {storeToRefs} from 'pinia';

const userPreferencesStore = useUserPreferencesStore();
const {colorScheme} = storeToRefs(userPreferencesStore);

watch(colorScheme, (value) => {
  document.body.classList.remove('force-bright-scheme');
  document.body.classList.remove('force-dark-scheme');
  if (value === ColorScheme.dark) {
    document.body.classList.add('force-dark-scheme');
  } else if (value === ColorScheme.bright) {
    document.body.classList.add('force-bright-scheme');
  }
}, {immediate: true});

</script>

<template>
  <div class="page-container">
    <router-view/>
  </div>
</template>
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
@import '@/assets/sass/global-styles';
@import '@/assets/sass/global-color-scheme';
@import '@/assets/sass/global-variables';
</style>
<style lang="scss" scoped>
.page-container {
  display: flex;
  max-width: 100%;
  height: 100%;
}
</style>
