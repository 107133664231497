<script lang="ts" setup>

import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {computed} from 'vue';
import {PartLeftBlockClass} from '@/ts/types/component/part-left-block';
import {
  useTableOfContentsVisibilityQueryParamFlag,
} from '@/ts/composables/stateful/use-table-of-contents-visibility-query-param-flag';

const props = defineProps<{
  title: string;
  description?: string;
  state: PartLeftBlockClass;
  to?: string;
}>();

const {encodedSearchParams} = useTableOfContentsVisibilityQueryParamFlag();

const link = computed(() => {
  if (props.state === PartLeftBlockClass.NOT_STARTED || !props.to) {
    return null;
  }

  return `${props.to}?${encodedSearchParams.value}`;
});
</script>
<template>
  <q-btn
    flat
    class="part-left focus-visible"
    :class="state"
    :to="link"
    :ripple="!!link"
    :disable="!link"
  >
    <div class="part-left-texts">
      <h3>{{ title }}</h3>
      <h3 v-if="description">
        {{ description }}
      </h3>
      <slot/>
    </div>
    <div class="part-left-svg">
      <svg-illustration v-if="state === PartLeftBlockClass.CURRENT_PAGE" name="arrow-narrow-right.svg"/>
      <svg-illustration v-else-if="state === 'completed'" name="check.svg"/>
    </div>
  </q-btn>
</template>
<style lang="scss" scoped>
.active {
  background-color: var(--Primary-primary-color-4);
}

.part-left {
  width: 100%;
  display: flex;
  position: relative;
  padding: var(--Spacing-spacing-3);
  @include body-small-3;

  :deep(.q-btn__content) {
    gap: var(--Spacing-spacing-1);
  }

  &.q-btn.disabled {
    opacity: 1 !important;
  }
}

.not-started {
  @include q-card;
  box-shadow: 0 var(--Spacing-spacing-1) var(--Spacing-spacing-3) 0 rgba(0, 0, 0, 0.05);
  cursor: default;

  :deep(.q-focus-helper) {
    display: none;
  }
}

.current {
  @include q-card-blue;
  flex-direction: row;
}

.in-progress {
  @include q-card-white;
  flex-direction: row;
}

.completed {
  @include q-card-green;
  flex-direction: row;
}

.part-left-texts {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-1);
  margin-right: auto;
  align-items: start;
}

.part-left-svg {
  align-self: end;
  margin-left: auto;
}

h3 {
  @include body-small-3;
  text-align: left;
}
</style>
