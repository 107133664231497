import {defineStore} from 'pinia';
import {ref, watch} from 'vue';

const STORAGE_KEY = 'preferred-color-scheme';

// eslint-disable-next-line import/exports-last
export enum ColorScheme {
  dark = 'dark',
  bright = 'bright',
  adaptive = 'adaptive',
};

export const useUserPreferencesStore = defineStore('user-preferences-store', () => {
  // default is adaptive, means we respect the current settings of the OS
  // TODO we could override the colorTheme to 'bright' or 'dark' on a user-profile page. Simple set this state:
  const colorScheme = ref<ColorScheme>(localStorage.getItem(STORAGE_KEY) as ColorScheme || ColorScheme.adaptive);

  watch(
    colorScheme,
    (newScheme: ColorScheme): void => {
      if ([ColorScheme.bright, ColorScheme.dark, ColorScheme.adaptive].includes(newScheme)) {
        localStorage.setItem(STORAGE_KEY, newScheme);
      }
    },
    {immediate: true},
  );

  return {colorScheme};
});


