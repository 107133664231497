<script lang="ts" setup>
import {useLoadingData} from '@/ts/composables/pure/use-loading-data-ref';
import {useApi} from '@/ts/composables/stateful/use-api';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {useCreateIntervention} from '@/ts/store/intervention-store';
import InterventionHero from '@/vue/organisms/intervention-landing/intervention-hero.vue';
import InterventionLandingDescription from '@/vue/organisms/intervention-landing/intervention-landing-description.vue';
import InterventionLandingSessions from '@/vue/organisms/intervention-landing/intervention-landing-sessions.vue';
import InterventionLandingGoals from '@/vue/organisms/intervention-landing/intervention-landing-goals.vue';
import LoadingError from '@/vue/templates/loading-error.vue';
import {onMounted} from 'vue';

const {iid} = useRouterSectionParams();

const api = useApi();
const intervention = useCreateIntervention(null, 'intervention-landing-page');

const [fetchIntervention, load, err] = useLoadingData(intervention, async() => api.me.getIntervention(iid.value!));

onMounted(async() => {
  await fetchIntervention();
});

</script>
<template>
  <loading-error id="intervention-landing-page" :loading="load" :error="err" type="page">
    <div v-if="intervention" class="intervention-landing-page">
      <intervention-hero :intervention="intervention"/>
      <intervention-landing-description :description="intervention.description"/>
      <intervention-landing-sessions :sessions="intervention.sessions"/>
      <intervention-landing-goals :goals="intervention.goals"/>
    </div>
  </loading-error>
</template>
<style lang="scss" scoped>
.intervention-landing-page {
  @include q-page;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include screen-md {
    gap: 24px;
  }

  @include screen-lg {
    gap: 32px;
  }
}
</style>
