<script setup lang="ts">
import type {Session} from '@/ts/types/dto/interventions-dto';
import {useI18n} from 'vue-i18n';
import InterventionLandingSession from '@/vue/organisms/intervention-landing/intervention-landing-session.vue';

const {t: $t} = useI18n();

defineProps<{ sessions: Session[] }>();

</script>

<template>
  <div class="intervention-landing-sessions">
    <h3 class="title">
      {{ $t('sessions') }}
    </h3>
    <q-scroll-area style="height: 260px; max-width: 100%;">
      <div class="row no-wrap sessions">
        <div v-for="(session, index) in sessions" :key="session.id" class="session">
          <intervention-landing-session
            :index="index"
            :session="session"
          />
        </div>
      </div>
    </q-scroll-area>
  </div>
</template>

<style scoped lang="scss">
.intervention-landing-sessions {
  border-radius: var(--Border-Radius-1);
  background-color: var(--Neutral-UI-neutral-ui-0);
  padding: 32px 42px 42px 24px;

  @include screen-md {
    padding: 42px 48px 48px 32px;
  }

  @include screen-lg {
    padding: 48px 64px 64px 40px;
  }
}

.title {
  @include heading-3;
  margin-bottom: 16px;

  @include screen-md {
    margin-bottom: 24px;
  }

  @include screen-lg {
    margin-bottom: 32px;
  }
}

.sessions {
  display: flex;
  gap: 4px;

  @include screen-md {
    gap: 8px;
  }

  @include screen-lg {
    gap: 12px;
  }
}
</style>
