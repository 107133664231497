<script setup lang="ts">
import {useI18n} from 'vue-i18n';

const {t: $t} = useI18n();

defineProps<{ description: string }>();

</script>

<template>
  <div class="intervention-landing-description">
    <h3 class="title">
      {{ $t('summary') }}
    </h3>
    <div class="description" v-html="description"/>
  </div>
</template>

<style scoped lang="scss">
.intervention-landing-description {
  padding: 32px 42px 42px 24px;

  @include screen-md {
    padding: 42px 48px 48px 32px;
  }

  @include screen-lg {
    padding: 48px 64px 64px 40px;
  }
}

.title {
  @include heading-3;
  margin-bottom: 12px;

  @include screen-md {
    margin-bottom: 16px;
  }

  @include screen-lg {
    margin-bottom: 24px;
  }
}

.description {
  @include body
}
</style>
