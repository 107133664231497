<script setup lang="ts">
import type {
  PrimaryFeeling,
  SecondaryFeeling,
} from '@/ts/types/dto/interventions-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import FeelingsPopup from '@/vue/molecules/components/helpers/feelings-popup.vue';
import {
  computed, getCurrentInstance,
  ref,
} from 'vue';
import SessionTwoBlocks from '@/vue/molecules/components/helpers/session-two-blocks.vue';
import FeelingChip from '@/vue/molecules/feeling-chip.vue';
import FormRequired from '@/vue/atoms/form-required.vue';
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';

const props = defineProps<{
  feelings: PrimaryFeeling[];
  descriptionHtml?: string;
}>();

defineEmits<{
  add: [],
}>();

const primaryModel = defineModel<UuidDTO[]>('primary');
const secondaryModel = defineModel<UuidDTO[]>('secondary');

const open = ref(false);
const instance = getCurrentInstance();

const values = computed<UuidDTO[]>({
  get() {
    return [...primaryModel.value!, ...secondaryModel.value!] as UuidDTO[];
  },
  set(value: UuidDTO[]) {
    primaryModel.value = value.filter((id) => props.feelings.some((feeling) => feeling.id === id));
    secondaryModel.value = value.filter((id) => !props.feelings.some((feeling) => feeling.id === id));
  },
});

const feelingsMap = computed(() => {
  const all: Record<UuidDTO, string> = {};
  props.feelings.forEach((feeling) => {
    all[feeling.id] = feeling.name;
    feeling.secondaryFeelings.forEach((secondaryFeeling: SecondaryFeeling) => {
      all[secondaryFeeling.id] = secondaryFeeling.name;
    });
  });
  return all;
});

function removeItem(val: UuidDTO): void {
  if (primaryModel.value!.includes(val)) {
    primaryModel.value?.splice(primaryModel.value!.indexOf(val), 1);
  } else {
    secondaryModel.value?.splice(secondaryModel.value!.indexOf(val), 1);
  }
}

const listLabelId = computed((): string => {
  return `feelings-list-label-${instance?.uid}`;
});
</script>
<template>
  <session-two-blocks :name="$t('feelings')" :description-html="descriptionHtml">
    <div class="right">
      <span :id="listLabelId" class="label">
        {{ $t('input_your_feelings') }}
      </span>
      <div
        class="feelings"
        :aria-labelledby="listLabelId"
        role="list"
      >
        <feeling-chip
          v-for="value in values"
          :key="value"
          class="feeling-chip"
          :name="feelingsMap[value]"
          role="listitem"
          @remove="removeItem(value)"
        />
      </div>
      <div class="cta-bar">
        <flat-icon-btn
          icon="plus.svg"
          :label="$t('input_your_feelings_add')"
          @click="open = true"
        />
        <form-required id="feelings-block" :valid="values.length > 0" :message="$t('select_one_option')"/>
      </div>
    </div>
    <feelings-popup v-model:ids="values" v-model:is-open="open" :feelings="feelings"/>
  </session-two-blocks>
</template>
<style scoped lang="scss">
@mixin feelings-block-right-broad {
  @container feelings-block-right (min-width: 350px) {
    @content;
  }
}

.feelings {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: var(--Spacing-spacing-2);
  flex-direction: column;

  @include feelings-block-right-broad {
    flex-direction: row;
  }
}

.feeling-chip {
  @include feelings-block-right-broad {
    width: fit-content;
  }
}

.right {
  container-type: inline-size;
  container-name: feelings-block-right;
  background-color: var(--color-white);
  width: 100%;
  @include feelings-block-right-broad {
    padding: var(--Spacing-spacing-4);
  }
}

.label {
  @include body-medium-3;
  display: block;
  margin-bottom: var(--Spacing-spacing-2);
}

.cta-bar {
  margin-top: var(--Spacing-spacing-2);
}
</style>
