<script setup lang="ts">
import type {Goal} from '@/ts/types/dto/interventions-dto';

defineProps<{
  goal: Goal;
  order: number;
}>();
</script>
<template>
  <div class="goal">
    <div class="order">
      {{ order }}
    </div>
    <div class="name">
      {{ goal.name }}
    </div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span class="body" v-html="goal.descriptionHTML"/>
  </div>
</template>
<style scoped lang="scss">
.goal {
  width: 240px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  @include q-card-10;
  @include q-card-big;

  @include screen-md {
    width: 320px
  }
}

.order {
  @include satoshi-40;
}

.name {
  @include body-medium-3;
}

.body {
  @include body-small-2
}
</style>
