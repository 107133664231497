import {
  nextTick,
  type Ref,
} from 'vue';
import type {ComponentPublicInstance} from '@vue/runtime-core';
import {useLogger} from '@/ts/composables/pure/use-logger';

export function useScrollTo(form: Ref<ComponentPublicInstance>, reserveSpace: number): () => void {
  const logger = useLogger();
  return () => {
    let el: HTMLElement | null = (form.value.$el as HTMLElement).firstChild as HTMLElement;
    let scrollContainer: HTMLElement | null = null;

    while (el) {
      // looking for scroll-container
      // the scroll container could be a different element on mobile and desktop
      const {overflowY} = window.getComputedStyle(el);
      if (overflowY === 'scroll' || (overflowY === 'auto' && el.scrollHeight > el.clientHeight)) {
        scrollContainer = el;
        el = null;
      } else {
        el = el.parentElement;
      }
    }

    if (scrollContainer) {
      const oldScrollHeight = scrollContainer.scrollHeight;
      void nextTick(() => {
        const containerRect = scrollContainer;
        logger.debug(`Scrolling to ${oldScrollHeight - reserveSpace}`)();
        containerRect.scrollTo({top: oldScrollHeight - reserveSpace, behavior: 'smooth'});
      });
    }
  };
}
