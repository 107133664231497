<script setup lang="ts">

import {useSignIn} from '@/ts/composables/stateful/use-auth';
import {useLoadingNotification} from '@/ts/composables/pure/use-loading-notification';
import {onMounted} from 'vue';

const signIn = useSignIn();
const [submit, _] = useLoadingNotification(async() => signIn());

onMounted(async() => submit());

</script>
<template>
  <div/>  
</template>
