<script setup lang="ts">
import type {Goal} from '@/ts/types/dto/interventions-dto';
import {useI18n} from 'vue-i18n';
import InterventionLandingGoal from '@/vue/organisms/intervention-landing/intervention-landing-goal.vue';
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';

const {t: $t} = useI18n();

defineProps<{ goals: Goal[] }>();

const {isDesktopResolution} = useScreenSize();
</script>

<template>
  <div class="intervention-landing-goals">
    <h3 class="title">
      {{ $t('path_goals') }}
    </h3>
    <q-scroll-area :style="`height: ${isDesktopResolution ? '420px' : '360px'}; max-width: 100%;`">
      <div class="row no-wrap goals">
        <div v-for="(goal, index) in goals" :key="goal.id" class="goal">
          <intervention-landing-goal :goal="goal" :order="index + 1"/>
        </div>
      </div>
    </q-scroll-area>
  </div>
</template>

<style scoped lang="scss">
.intervention-landing-goals {
  border-radius: var(--Border-Radius-1);
  background-color: var(--Neutral-UI-neutral-ui-0);
  padding: 32px 42px 42px 24px;

  @include screen-md {
    padding: 42px 48px 48px 32px;
  }

  @include screen-lg {
    padding: 48px 64px 64px 40px;
  }
}

.title {
  @include heading-3;
  margin-bottom: 16px;

  @include screen-md {
    margin-bottom: 24px;
  }

  @include screen-lg {
    margin-bottom: 32px;
  }
}

.goals {
  display: flex;
  gap: 4px;

  @include screen-md {
    gap: 8px;
  }

  @include screen-lg {
    gap: 12px;
  }
}
</style>
