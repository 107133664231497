<script setup lang="ts">
/* eslint-disable max-lines */
import {pagesPath} from '@/ts/router/pages-path';
import {
  PublicationState,
  type Intervention,
} from '@/ts/types/dto/interventions-dto';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import balmBtn from '@/vue/atoms/balm-btn.vue';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {useInterventionLinks} from '@/ts/composables/stateful/use-links';

const {t: $t} = useI18n();

const props = defineProps<{ intervention: Intervention }>();

const {currentSessionLink} = useInterventionLinks();

const stateLabel = computed(() => {
  if (props.intervention.publicationState === PublicationState.IN_PROGRESS) {
    return $t('current');
  }
  if (props.intervention.publicationState === PublicationState.COMPLETED) {
    return $t('completed');
  }
  return '';
});

const theme = computed(() => {
  if (props.intervention.publicationState === PublicationState.IN_PROGRESS) {
    return 'blue';
  }
  if (props.intervention.publicationState === PublicationState.COMPLETED) {
    return 'green';
  }
  return 'grey';
});

</script>

<template>
  <div class="intervention-hero" :class="theme">
    <div class="flex justify-between items-center q-mb-sm">
      <q-btn class="back-btn focus-visible" flat dense :to="pagesPath.intervention.interventionsList">
        <svg-illustration name="chevron-left.svg" aria-hidden="true"/>
        <span class="label">{{ $t('all_paths') }}</span>
      </q-btn>
      <div v-if="stateLabel" class="state">
        <span>{{ stateLabel }}</span>
      </div>
    </div>
    <div class="grid-container">
      <div class="image">
        <lazy-image :src="intervention.image!"/>
      </div>
      <div class="info">
        <div class="caption">
          <span>{{ $t('path') }}</span>
        </div>
        <h1 class="title">
          {{ intervention.name }}
        </h1>
        <balm-btn
          v-if="currentSessionLink"
          :label="$t('continue')"
          :to="currentSessionLink!"
          theme="outline"
          paddings="p3-7"
          icon="arrow-narrow-right.svg"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.intervention-hero {
  border-radius: 12px;
  min-height: 300px;
  padding: 24px 32px 24px 24px;
  background-color: var(--Neutral-UI-neutral-ui-20);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.blue {
    background-color: var(--Secondary-secondary-color-1);
  }
  &.green {
    background-color: var(--Secondary-secondary-color-2);
  }

  @include screen-md {
    min-height: 500px;
    padding: 32px 42px 32px 32px;
  }

  @include screen-lg {
    padding: 40px 48px 40px 40px;
  }
}

.back-btn {
  @include body-bold;
}

.state {
  border-radius: var(--button-border-radius);
  @include caption-small;
  color: var(--Neutral-UI-neutral-ui-100);
  height: fit-content;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  background-color: var(--Neutral-UI-neutral-ui-10);
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 12px;
  grid-template-areas:
    'image'
    'info';
  align-items: flex-end;

  @include screen-md {
    gap: 16px;
  }

  @include screen-lg {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'info image';
    gap: 24px;
  }
}

.info {
  grid-area: info;
}

.image {
  grid-area: image;
}

.caption {
  width: fit-content;
  border-radius: var(--button-border-radius);
  border: 2px solid var(--Neutral-UI-neutral-ui-100);
  @include caption-small;
  color: var(--Neutral-UI-neutral-ui-100);
  height: fit-content;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  @include screen-md {
    padding: 8px 12px;
    margin-bottom: 8px;
  }

  @include screen-lg {
    padding: 12px 16px;
    margin-bottom: 12px;
  }
}

.title {
  margin-bottom: 16px;

  @include screen-md {
    margin-bottom: 24px;
  }

  @include screen-lg {
    margin-bottom: 32px;
  }
}
</style>
